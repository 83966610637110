import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  phrases: null,
  phrase: null
};

const getters = {
  getPhrases: state => state.phrases,
  getLengthPhrases: state => state.phrases !== null ? state.phrases.data.length : 0,
  getPhrase: state => state.phrase
};

const mutations = {
  setPhrases (state, data) {
    state.phrases = data;
  },
  setPhrase (state, data) {
    state.phrase = data.data;
  }
};

const actions = {
  actionGetPhrases ({ commit }, payload) {
    api.get('camp/phrases', { params: payload })
      .then((response) => {
        commit('setPhrases', response.data);
      })
      .catch(() => {
      });
  },
  actionAddPhrase ({ commit }, payload) {
    return api.post('camp/phrases', payload);
  },
  actionEditPhrase ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    api.post('camp/phrases/' + payload.id, payload);
  },
  actionRemovePhrase ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    api.post('camp/phrases/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
