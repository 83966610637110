import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  publications: {},
  publication: {},
  count: null
};

const getters = {
  getPublications: state => state.publications,
  getPublication: state => state.publication,
  getLengthPublications: state => state.count !== null ? state.count : 0
};

const mutations = {
  setPublications (state, data) {
    state.publications = data.data;
  },
  setPublication (state, data) {
    state.publication = data.data;
  },
  setCount (state, data) {
    state.count = data;
  }
};

const actions = {
  actionGetPublications ({ commit }, payload) {
    return api.get('camp/publications', { params: payload })
      .then((response) => {
        commit('setPublications', response.data);
        commit('setCount', response.data.meta.total);
      }
      );
  },
  actionGetPublication ({ commit }, payload) {
    return api.get('camp/publications/' + payload + '/edit')
      .then((response) => {
        commit('setPublication', response.data);
      });
  },
  actionAddPublication ({ commit }, payload) {
    return api.post('camp/publications', payload)
      .then((response) => {
        router.push({ name: 'camp.publications.index' });
      }
      );
  },
  actionEditPublication ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/publications/' + payload.id, payload);
  },
  actionRemovePublication ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/publications/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
