import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  operators: null,
  operator: null
};

const getters = {
  getOperators: state => state.operators,
  getOperator: state => state.operator
};

const mutations = {
  setOperators (state, data) {
    state.operators = data.data;
  },
  setOperator (state, data) {
    state.operator = data.data;
  }
};

const actions = {
  actionGetOperators ({ commit }) {
    return api.get('camp/operators')
      .then((response) => {
        commit('setOperators', response.data);
      }
      );
  },
  actionGetChildren ({ commit }, data) {
    return api.get('camp/operators/get_children', { params: data });
  },
  actionUp ({ commit }, payload) {
    return api.post('camp/operators/set_up', payload);
  },
  actionDown ({ commit }, payload) {
    return api.post('camp/operators/set_down', payload);
  },
  actionTop ({ commit }, payload) {
    return api.post('camp/operators/set_top', payload);
  },
  actionGetOperator ({ commit }, payload) {
    return api.get('camp/operators/' + payload + '/edit')
      .then((response) => {
        commit('setOperator', response.data);
      });
  },
  actionAddOperator ({ commit }, payload) {
    return api.post('camp/operators', payload)
      .then((response) => {
        router.push({ name: 'camp.operator.index' });
      }
      );
  },
  actionEditOperator ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/operators/' + payload.id, payload);
  },
  actionRemoveOperator ({ commit }, payload) {
    return api.delete('camp/operators/' + payload.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
