import { CommonStore } from '../common-store';
import api from '@/services/gateway/api';

const storeModule = new CommonStore(
  'camp/notifications',
  { name: 'camp.site.notifications.index' }
);

storeModule.actions.getItem = function ({ commit }, payload) {
  return api.get('camp/notifications/' + payload)
    .then((response) => {
      commit('setItem', response.data.data);
    });
};

storeModule.actions.getPaths = function ({ commit }, payload) {
  return api.get('camp/notifications/paths', { params: payload })
    .then((response) => {
      commit('setPaths', response.data.data);
    });
};

storeModule.state.paths = [];

storeModule.getters.getPaths = state => state.paths;

storeModule.mutations.setPaths = function (state, data) {
  state.paths = data;
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
