// initial state
const state = {
  isMobileMenuOpened: false
};

// getters
const getters = {
  isMobileMenuOpened: state => state.isMobileMenuOpened
};

// mutations
const mutations = {
  toggleMobileMenu (state) {
    state.isMobileMenuOpened = !state.isMobileMenuOpened;
  },
  closeMobileMenu (state) {
    state.isMobileMenuOpened = false;
  }
};

// actions
const actions = {
  toggleMobileMenu ({ commit }) {
    document.body.classList.toggle('show-mobile-menu');
    commit('toggleMobileMenu');
  },
  closeMobileMenu ({ commit }) {
    document.body.classList.remove('show-mobile-menu');
    commit('closeMobileMenu');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
