import { CommonStore } from '../common-store';
import api from '@/services/gateway/api';

const storeModule = new CommonStore(
  'camp/products/types',
  { name: 'camp.products.types.index' }
);

storeModule.actions.getItem = function ({ commit }, payload) {
  return api.get('camp/products/types/' + payload)
    .then((response) => {
      commit('setItem', response.data.data);
    });
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
