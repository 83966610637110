import Vue from './vue-instance';
import router from './vue-instance/router';
import store from './vue-instance/store';
import App from './App.vue';
import './registerServiceWorker';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
