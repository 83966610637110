import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  additional_services: [],
  additional_service: {}
};

const getters = {
  getAdditionalServices: state => state.additional_services,
  getAdditionalService: state => state.additional_service
};

const mutations = {
  setAdditionalServices (state, data) {
    state.additional_services = data;
  },
  setAdditionalService (state, data) {
    state.additional_service = data.data;
  }
};

const actions = {
  actionGetAdditionalServices ({ commit }) {
    return api.get('camp/additional_services')
      .then((response) => {
        commit('setAdditionalServices', response.data.data);
      }
      );
  },
  actionGetAdditionalService ({ commit }, payload) {
    return api.get('camp/additional_services/' + payload + '/edit')
      .then((response) => {
        commit('setAdditionalService', response.data);
      });
  },
  actionAddAdditionalService ({ commit }, payload) {
    return api.post('camp/additional_services', payload)
      .then((response) => {
        router.push({ name: 'camp.additional_services.index' });
      }
      );
  },
  actionEditAdditionalService ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/additional_services/' + payload.id, payload);
  },
  actionRemoveAdditionalService ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/additional_services/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
