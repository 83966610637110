import router from '@/vue-instance/router';
import api from '@/services/gateway/api';

export class CommonStore {
  constructor (baseUrl, indexRoute, namespaced = true) {
    const self = this;
    this.namespaced = namespaced;
    this.baseUrl = baseUrl;
    this.indexRoute = indexRoute;

    this.state = {
      list: [],
      item: {}
    };

    this.getters = {
      getList: (state) => state.list,
      getItem: (state) => state.item
    };

    this.mutations = {
      setList (state, data) {
        state.list = data;
      },
      setItem (state, data) {
        state.item = data;
      }
    };

    this.actions = {
      getList ({ commit }, payload) {
        return api.get(self.baseUrl, { params: payload })
          .then((response) => {
            commit('setList', response.data.data);
          });
      },
      getItem ({ commit }, payload) {
        return api.get(self.baseUrl + '/' + payload + '/edit')
          .then((response) => {
            commit('setItem', response.data.data);
          });
      },
      addItem ({ commit }, payload) {
        return api.post(self.baseUrl, payload)
          .then((response) => {
            router.push(self.indexRoute);
          });
      },
      editItem ({ commit }, payload) {
        payload['_method'] = 'PATCH';
        return api.post(self.baseUrl + '/' + payload.id, payload);
      },
      removeItem ({ commit }, payload) {
        payload['_method'] = 'DELETE';
        return api.post(self.baseUrl + '/' + payload.id, payload);
      }
    };
  }
}
