import api from '@/services/gateway/api';
import router from '@/vue-instance/router';
import store from '@/vue-instance/store';

const state = {
  main_services: [],
  main_service: {}
};

const getters = {
  getMainServices: state => state.main_services,
  getMainService: state => state.main_service
};

const mutations = {
  setMainServices (state, data) {
    state.main_services = data;
  },
  setMainService (state, data) {
    state.main_service = data.data;
  }
};

const actions = {
  actionGetMainServices ({ commit }) {
    return api.get('camp/main_services')
      .then((response) => {
        commit('setMainServices', response.data.data);
      }
      );
  },
  actionGetMainService ({ commit }, payload) {
    return api.get('camp/main_services/' + payload + '/edit')
      .then((response) => {
        commit('setMainService', response.data);
      });
  },
  actionAddMainService ({ commit }, payload) {
    return api.post('camp/main_services', payload)
      .then((response) => {
        router.push({ name: 'camp.main_services.index' });
      }
      );
  },
  actionEditMainService ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/main_services/' + payload.id, payload);
  },
  actionRemoveMainService ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/main_services/' + payload.id, payload);
  },
  actionSyncSubserviceText ({ dispatch }, payload) {
    return api.post(
      'camp/main_services/sync_subservice_texts/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetMainServices');
        store.dispatch('camp/subserviceTexts/actionGetSubserviceTexts');
      }
      );
  },
  actionSyncSubserviceSelects ({ dispatch }, payload) {
    return api.post(
      'camp/main_services/sync_subservice_selects/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetMainServices');
        store.dispatch('camp/subserviceSelects/actionGetSubserviceSelects');
      }
      );
  },
  actionSyncRateDetails ({ dispatch }, payload) {
    return api.post(
      'camp/main_services/sync_rate_details/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetMainServices');
        store.dispatch('camp/rateDetails/actionGetRateDetails');
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
