import mainServiceTypes from './modules/main-service-types';
import directions from './modules/directions';
import regions from './modules/regions';

export default {
  namespaced: true,
  modules: {
    mainServiceTypes,
    directions,
    regions
  }
};
