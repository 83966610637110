import { appConfig } from '@/config/app';

export class LanguageChecker {
  static isLanguageAvailable (language:string): boolean {
    return appConfig.availableLanguages.indexOf(language) !== -1;
  }

  static getCorrectLanguage (language:string = ''):string {
    if (!LanguageChecker.isLanguageAvailable(language)) {
      language = appConfig.defaultLanguage;
    }
    return language;
  }
}
