import api from '@/services/gateway/api';

const state = {
  list: [],
  listLength: 0,
  operator: {}
};

const getters = {
  getList: state => state.list,
  getListLength: state => state.listLength,
  getOperator: state => state.operator
};

const mutations = {
  setList (state, data) {
    state.list = data;
  },
  setlistLength (state, data) {
    state.listLength = data;
  },
  setItem (state, data) {
    state.operator = data;
  }
};

const actions = {
  actionGetList ({ commit }, payload) {
    return api.get('camp/sapa/operators', { params: payload }).then((response) => {
      commit('setList', response.data.data);
      commit('setlistLength', response.data.meta.total);
    }
    ).catch(() => {});
  },
  actionGetOperator ({ commit }, id) {
    return api.get(`camp/sapa/operators/${id}`).then((response) => {
      commit('setItem', response.data.data);
    }
    ).catch(() => {});
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
