import api from '@/services/gateway/api';
import router from '@/vue-instance/router';
import store from '@/vue-instance/store';

const state = {
  single_services: [],
  single_service: {}
};

const getters = {
  getSingleServices: state => state.single_services,
  getSingleService: state => state.single_service
};

const mutations = {
  setSingleServices (state, data) {
    state.single_services = data;
  },
  setSingleService (state, data) {
    state.single_service = data.data;
    state.single_service.text_one = state.single_service.text_one === 'null' ? '' : state.single_service.text_one;
    state.single_service.text_two = state.single_service.text_two === 'null' ? '' : state.single_service.text_two;
  }
};

const actions = {
  actionGetSingleServices ({ commit }) {
    return api.get('camp/single_services')
      .then((response) => {
        commit('setSingleServices', response.data.data);
      }
      );
  },
  actionGetSingleService ({ commit }, payload) {
    return api.get('camp/single_services/' + payload + '/edit')
      .then((response) => {
        commit('setSingleService', response.data);
      });
  },
  actionAddSingleService ({ commit }, payload) {
    if (payload.hasOwnProperty('bigImageUrl')) delete payload['bigImageUrl'];
    return api.post('camp/single_services', payload)
      .then((response) => {
        router.push({ name: 'camp.single_services.index' });
      }
      );
  },
  actionEditSingleService ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    if (payload.hasOwnProperty('bigImageUrl')) delete payload['bigImageUrl'];
    return api.post('camp/single_services/' + payload.id, payload);
  },
  actionRemoveSingleService ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/single_services/' + payload.id, payload);
  },
  actionSyncServiceType ({ dispatch }, payload) {
    return api.post(
      'camp/single_services/sync_service_types/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetSingleServices');
        store.dispatch('camp/serviceTypes/actionGetServicesTypes');
      }
      );
  },
  actionSyncMainServices ({ dispatch }, payload) {
    return api.post(
      'camp/single_services/sync_main_services/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetSingleServices');
        store.dispatch('camp/mainServices/actionGetMainServices');
      }
      );
  },
  actionSyncBlockType ({ dispatch }, payload) {
    return api.post(
      'camp/single_services/sync_block_types/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetSingleServices');
        store.dispatch('camp/blockTypes/actionGetBlocksTypes');
      }
      );
  },
  actionSyncAdvantages ({ dispatch }, payload) {
    return api.post(
      'camp/single_services/sync_advantages/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetSingleServices');
        store.dispatch('camp/advantages/actionGetAdvantages');
      }
      );
  },
  actionSyncBlocks ({ dispatch }, payload) {
    return api.post(
      'camp/single_services/sync_blocks/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetSingleServices');
        store.dispatch('camp/blocks/actionGetBlocks');
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
