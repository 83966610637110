import router from '@/vue-instance/router';
import api from '@/services/gateway/api';

const state = {
  hits: null,
  hit: {}
};

const getters = {
  getHits: state => state.hits,
  getLengthHits: state => state.hits !== null ? state.hits.length : 0,
  getHit: state => state.hit
};

const mutations = {
  setHits (state, data) {
    state.hits = data;
  },
  setHit (state, data) {
    state.hit = data.data;
  }
};

const actions = {
  actionGetHits ({ commit }) {
    return api.get('camp/hits')
      .then((response) => {
        commit('setHits', response.data);
      }
      );
  },
  actionGetHit ({ commit }, payload) {
    return api.get('camp/hits/' + payload + '/edit')
      .then((response) => {
        commit('setHit', response.data);
      });
  },
  actionAddHit ({ commit }, payload) {
    if (payload.hasOwnProperty('imageUrl')) delete payload['imageUrl'];

    return api.post('camp/hits', payload)
      .then((response) => {
        router.push({ name: 'camp.hits' });
      }
      );
  },
  actionEditHit ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    if (payload.hasOwnProperty('imageUrl')) delete payload['imageUrl'];

    return api.post('camp/hits/' + payload.id, payload);
  },
  actionRemoveHit ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/hits/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
