import api from '@/services/gateway/api';

const state = {
  list: [],
  listLength: 0,
  listActiveLength: 0,
  listCancelledLength: 0,
  listCompletedNotConfirmedLength: 0,
  listCompletedLength: 0,
  item: {},
  divisionsOptions: [],
  departamentsOptions: [],
  regionsOptions: [],
  selectedAppeals: [],
  selectedAppealUser: [],
  users: [],
  usersPhones: [],
  allAppeals: [],
  allAppealsLength: 0
};

const getters = {
  getListAppeals: state => state.list,
  getListLength: state => state.listLength,
  getListActiveLength: state => state.listActiveLength,
  getListCanseledLength: state => state.listCancelledLength,
  getListLengthlistCompletedNotConfirmedLength: state => state.listCompletedNotConfirmedLength,
  getListCompletedLength: state => state.listCompletedLength,
  getItem: state => state.item,
  getDivisionsOptions: state => state.divisionsOptions,
  getDepartamentsOptions: state => state.departamentsOptions,
  getRegionsOptions: state => state.regionsOptions,
  getSelectedAppeals: state => state.selectedAppeals,
  getUsers: state => state.users,
  getUsersPhones: state => state.usersPhones,
  getSelectedAppealUser: state => state.selectedAppealUser,
  getAllAppeals: state => state.allAppeals,
  getAllAppealsLength: state => state.allAppealsLength
};

const mutations = {
  setListAppeals (state, data) {
    state.list = data;
  },
  setAllAppeals (state, data) {
    state.allAppeals = data;
  },
  setAllAppealsLength (state, data) {
    state.allAppealsLength = data;
  },
  setListLength (state, data) {
    state.listLength = data;
  },
  setListActiveLength (state, data) {
    state.listActiveLength = data;
  },
  setListCancelledLength (state, data) {
    state.listCancelledLength = data;
  },
  setListCompletedLength (state, data) {
    state.listCompletedLength = data;
  },
  setListCompletedNotConfirmedLength (state, data) {
    state.listCompletedNotConfirmedLength = data;
  },
  setItem (state, data) {
    state.item = data;
  },
  setDivisionsOptions (state, data) {
    state.divisionsOptions = data;
  },
  setDepartamentsOptions (state, data) {
    state.departamentsOptions = data;
  },
  setRegionsOptions (state, data) {
    state.regionsOptions = data;
  },
  setSelectedAppeals (state, data) {
    state.selectedAppeals = [
      ...state.selectedAppeals,
      data
    ];
  },
  unsetSelectedAppeals (state, data) {
    state.selectedAppeals = state.selectedAppeals.filter(item => item !== data);
  },
  unsetSelectedAppealUser (state, data) {
    state.selectedAppealUser = state.selectedAppealUser.filter((item) => item.id !== data);
  },
  unsetSelectedItems (state) {
    state.selectedAppeals = [];
  },
  unsetAllSelectedAppealUser (state) {
    state.selectedAppealUser = [];
  },
  setUsers (state, data) {
    state.users = data;
  },
  setUserPhone (state, data) {
    state.usersPhones = data;
  },
  setSelectedAppealUser (state, data) {
    state.selectedAppealUser = [
      ...state.selectedAppealUser,
      data
    ];
  }
};

const actions = {
  actionGetAllAppeals ({ commit }, payload) {
    return api.get('camp/sapa/requests', { params: payload }).then((response) => {
      commit('setAllAppeals', response.data.data);
      commit('setAllAppealsLength', response.data.meta.total);
    }
    ).catch(() => {});
  },
  actionGetListAppeals ({ commit }, { payload, completed }) {
    if (!completed) {
      return api.get('camp/sapa/requests/active', { params: payload }).then((response) => {
        commit('setListAppeals', response.data.data);
      }
      ).catch(() => {});
    } else {
      return api.get('camp/sapa/requests/completed', { params: payload }).then((response) => {
        commit('setListAppeals', response.data.data);
      }
      ).catch(() => {});
    }
  },
  actionGetListlength ({ commit }) {
    return api.get('camp/sapa/requests/count')
      .then((response) => {
        const install = response.data.find(item => item.status === 'install');
        const installation = response.data.find((item) => item.status === 'installation');
        const assigned = response.data.find(item => item.status === 'assigned');
        const created = response.data.find(item => item.status === 'created');
        const cancelled = response.data.find(item => item.status === 'cancelled');
        const completedNotConfirmed = response.data.find(item => item.status === 'completed_not_confirmed');
        const completedConfirmed = response.data.find(item => item.status === 'completed_confirmed');

        if (created) {
          commit('setListLength', created.count);
        }

        if (install || assigned || installation) {
          let sum = 0;
          if (install) {
            sum = sum + install.count;
          }

          if (installation) {
            sum = sum + installation.count;
          }

          if (assigned) {
            sum = sum + assigned.count;
          }

          commit('setListActiveLength', sum);
        }

        if (cancelled) {
          commit('setListCancelledLength', cancelled.count);
        }

        if (completedConfirmed) {
          commit('setListCompletedLength', completedConfirmed.count);
        }

        if (completedNotConfirmed) {
          commit('setListCompletedNotConfirmedLength', completedNotConfirmed.count);
        }
      }
      ).catch(() => {});
  },
  actionListSorted ({ commit, rootGetters }, payload) {
    const user = rootGetters['camp/auth/getUser'];
    payload.operator_id = user.operator_id || 1;

    return api.get('camp/sapa/requests/active/sort', { params: payload })
      .then((response) => {
        commit('setListAppeals', response.data.data);
      }
      );
  },
  actionGetRegions ({ commit }) {
    const data = [
      {
        value: 1,
        text: 'ГЦТ Астанателеком'
      },
      {
        value: 2,
        text: 'Карагандинская ОДТ'
      },
      {
        value: 3,
        text: 'Актюбинская ОДТ'
      },
      {
        value: 4,
        text: 'Атырауская ОДТ'
      },
      {
        value: 5,
        text: 'Павлодарская ОДТ'
      },
      {
        value: 6,
        text: 'Южно-Казахстанская ОДТ'
      },
      {
        value: 8,
        text: 'Костанайская ОДТ'
      },
      {
        value: 9,
        text: 'Северо-Казахстанская ОДТ'
      },
      {
        value: 10,
        text: 'Акмолинская ОДТ'
      },
      {
        value: 11,
        text: 'Мангистауская ОДТ'
      },
      {
        value: 12,
        text: 'Восточно-Казахстанская ОДТ'
      },
      {
        value: 13,
        text: 'Западно-Казахстанская ОДТ'
      },
      {
        value: 14,
        text: 'Жамбылская ОДТ'
      },
      {
        value: 15,
        text: 'Кызылординская ОДТ'
      },
      {
        value: 16,
        text: 'Алматинская ОДТ'
      },
      {
        value: 17,
        text: 'ГЦТ Алматытелеком'
      },
      {
        value: 18,
        text: 'Семипалатинск'
      }
    ];

    commit('setRegionsOptions', data);
    // return api.get('open/regions')
    //   .then((response) => {
    //     const rData = response.data.data.map((item) => ({
    //       text: item.name,
    //       value: item.server_id
    //     }));

    //     commit('setRegionsOptions', rData);
    //   }
    //   );
  },
  actionGetDivisions ({ commit }) {
    return api.get('camp/sapa/divisions')
      .then((response) => {
        const rData = response.data.map((item) => ({
          text: item.name,
          value: item.id
        }));

        commit('setDivisionsOptions', rData);
      }
      );
  },
  actionGetDepartaments ({ commit }, divisionId) {
    return api.get(`camp/sapa/departments?division_id=${divisionId}`)
      .then((response) => {
        const rData = response.data.data.map((item) => ({
          text: item.name,
          value: item.id
        }));

        commit('setDepartamentsOptions', rData);
      }
      );
  },
  actionSelectedItems ({ commit }, payload) {
    if (payload.value) {
      commit('setSelectedAppeals', payload.id);
      commit('setSelectedAppealUser', { id: payload.id, customer: payload.customerId });
    }

    if (!payload.value) {
      commit('unsetSelectedAppealUser', payload.id);
      commit('unsetSelectedAppeals', payload.id);
    }
  },
  actionGetUsers ({ commit }) {
    return api.get('camp/sapa/users')
      .then((response) => {
        if (response && response.data) {
          const optionstPhone = response.data.data ? response.data.data.map(item => item.phoneNumber) : response.data.map(item => item.phoneNumber);

          commit('setUsers', response.data);
          commit('setUserPhone', optionstPhone);
        }
      }
      );
  },
  actionGetUserByPhone ({ commit }, payload) {
    return api.get('camp/sapa/users', { params: { phoneNumber: payload } })
      .then((response) => {
        if (response && response.data) {
          const optionstPhone = response.data.data ? response.data.data.map(item => item.phoneNumber) : response.data.map(item => item.phoneNumber);

          commit('setUserPhone', optionstPhone);
          return response.data.data ? response.data.data : response.data;
        }
      }
      );
  },
  actionClearSelected ({ commit }) {
    commit('unsetSelectedItems');
    commit('unsetAllSelectedAppealUser');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
