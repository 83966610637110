import { NotFoundAxiosStrategyError } from '@/services/gateway/errors/not-found-axios-strategy-error';
import { StrategyResolver } from '@/services/gateway/utils/strategy-resolver';
import { AxiosStrategyInterface } from '@/services/gateway/strategy/contracts/axios-strategy-interface';

interface instancesList { [s: string]: AxiosStrategyInterface; }
const _loadedInstances: instancesList = {};

export class AxiosStrategyFactory {
  static make (url:string): Promise<AxiosStrategyInterface> {
    return new Promise((resolve, reject) => {
      const strategyName:string = StrategyResolver.extract(url);
      if (_loadedInstances.hasOwnProperty(strategyName)) {
        resolve(_loadedInstances[strategyName]);
      }

      import(/* webpackChunkName: "services" */'./strategy/' + strategyName + '-strategy')
        .then((module) => {
          _loadedInstances[strategyName] = module.default;
          resolve(_loadedInstances[strategyName]);
        })
        .catch(() => {
          reject(new NotFoundAxiosStrategyError(`Not found an axios strategy for the "${url}" url.`));
        });
    });
  }
}
