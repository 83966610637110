import api from '@/services/gateway/api';

// initial state
const state = {
  directions: []
};

// getters
const getters = {
  getDirections: state => state.directions
};

// mutations
const mutations = {
  setDirections (state, data) {
    state.directions = data;
  }
};

// actions
const actions = {
  setDirections ({ commit }, payload) {
    api.get('open/directions/get')
      .then((response) => {
        commit('setDirections', response.data.data);
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
