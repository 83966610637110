import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  purchases: null,
  purchase: null
};

const getters = {
  getPurchases: state => state.purchases,
  getPurchase: state => state.purchase
};

const mutations = {
  setPurchases (state, data) {
    state.purchases = data.data;
  },
  setPurchase (state, data) {
    state.purchase = data.data;
  }
};

const actions = {
  actionGetPurchases ({ commit }, data = null) {
    return api.get('camp/purchases', { params: data })
      .then((response) => {
        commit('setPurchases', response.data);
      });
  },
  actionGetChildren ({ commit }, data) {
    return api.get('camp/purchases/get_children', { params: data });
  },
  actionUp ({ commit }, payload) {
    return api.post('camp/purchases/set_up', payload);
  },
  actionDown ({ commit }, payload) {
    return api.post('camp/purchases/set_down', payload);
  },
  actionTop ({ commit }, payload) {
    return api.post('camp/purchases/set_top', payload);
  },
  actionGetPurchase ({ commit }, payload) {
    return api.get('camp/purchases/' + payload + '/edit')
      .then((response) => {
        commit('setPurchase', response.data);
      });
  },
  actionAddPurchase ({ commit }, payload) {
    return api.post('camp/purchases', payload)
      .then((response) => {
        router.push({ name: 'camp.purchases.index' });
      }
      );
  },
  actionEditPurchase ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/purchases/' + payload.id, payload);
  },
  actionRemovePurchase ({ commit }, payload) {
    return api.delete('camp/purchases/' + payload.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
