import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  subservice_options: [],
  subserviceOption: {}
};

const getters = {
  getSubserviceOptions: state => state.subservice_options,
  getSubserviceOption: state => state.subserviceOption
};

const mutations = {
  setSubserviceOptions (state, data) {
    state.subservice_options = data;
  },
  setSubserviceOption (state, data) {
    state.subserviceOption = data.data;
  }
};

const actions = {
  actionGetSubserviceOptions ({ commit }) {
    return api.get('camp/subservice_options')
      .then((response) => {
        commit('setSubserviceOptions', response.data.data);
      }
      );
  },
  actionGetSubserviceOption ({ commit }, payload) {
    return api.get('camp/subservice_options/' + payload + '/edit')
      .then((response) => {
        commit('setSubserviceOption', response.data);
      });
  },
  actionAddSubserviceOption ({ commit }, payload) {
    return api.post('camp/subservice_options', payload)
      .then((response) => {
        router.push({ name: 'camp.subservice_options.index' });
      }
      );
  },
  actionEditSubserviceOption ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/subservice_options/' + payload.id, payload);
  },
  actionRemoveSubserviceOption ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/subservice_options/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
