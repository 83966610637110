import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
};

const getters = {
};

const mutations = {

};

const actions = {
  async actionGetCampaigns ({ commit, rootState }, { size, page, filter }) {
    const { data: { data: segments } } = await api.get('camp/users/' + rootState.camp.auth.user.id + '/segments');

    try {
      const { data: { data } } = await api.get(
        `camp/aitu/push/campaigns${filter ? '/search' : ''}`,
        {
          params: {
            segments: segments.map((segment) => segment.value),
            size: size,
            pageNum: page,
            name: filter
          }
        }
      );

      return {
        items: data.campaigns,
        total: data.totalNotifications
      };
    } catch (e) {
      return {
        items: [],
        total: 0
      };
    }
  },
  actionCreateCampaign ({ rootState }, data) {
    return api.post('camp/aitu/push/campaigns', {
      ...data,
      createdBy: rootState.camp.auth.user.id
    }).then(() => {
      router.push({ name: 'camp.aitu.push.campaign.index' });
    });
  },
  async actionGetUserSegments ({ rootState }) {
    const { data: { data: segments } } = await api.get('camp/users/' + rootState.camp.auth.user.id + '/segments');
    return segments;
  },
  async actionGetCampaign (ctx, id) {
    const response = await api.get('camp/aitu/push/campaigns/' + id);
    return response.data.data;
  },
  actionDownloadExcel (ctx, id) {
    return api.get(`camp/aitu/push/campaigns/${id}/download`);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
