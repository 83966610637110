import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

// initial state
const state = {
  rules: [],
  rule: {}
};

// getters
const getters = {
  getRules: state => state.rules,
  getRule: state => state.rule
};

// mutations
const mutations = {
  setRules (state, data) {
    state.rules = data.data;
  },
  setRule (state, data) {
    state.rule = data.data;
  }
};

// actions
const actions = {
  actionAddRule ({ commit }, payload) {
    return api.post('camp/technical_rules', payload)
      .then((response) => {
        router.push({ name: 'camp.technical' });
      }
      );
  },
  actionGetRules ({ commit }) {
    return api.get('camp/technical_rules')
      .then((response) => {
        commit('setRules', response.data);
      }
      );
  },
  actionEditRule ({ dispatch }, payload) {
    payload['_method'] = 'PATCH';

    return api.post('camp/technical_rules/' + payload.id, payload).then((response) => {
      router.push({ name: 'camp.technical' });
    });
  },
  actionGetRule ({ commit }, id) {
    return api.get('camp/technical_rules/' + id + '/edit').then((response) => {
      commit('setRule', response.data);
    }
    );
  },
  actionDeleteRule ({ commit }, payload) {
    payload['_method'] = 'DELETE';

    return api.post('camp/technical_rules/' + payload.id, payload);
  },
  actionSyncRules ({ dispatch }, payload) {
    return api.post(
      'camp/technical_rules/sync_rules/' + payload.id,
      {
        items: payload.items
      })
      .then(() => {
        dispatch('actionGetRules');
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
