import { CommonStore } from '../common-store';
import api from '@/services/gateway/api';
import moment from 'moment';

const storeModule = new CommonStore(
  'camp/recurrent-payments',
  { name: 'camp.recurrent-payments.index' }
);

storeModule.actions.getList = function ({ commit }, payload) {
  return api.get('camp/recurrent-payments', {
    params: {
      page: payload.page,
      id: payload.filter.id,
      filial_id: payload.filter.filial_id,
      autopayment_amount: payload.filter.autopayment_amount,
      autopayment_day: payload.filter.autopayment_day
    }
  })
    .then((response) => {
      commit('setList', response.data.data);
      commit('setCount', response.data.meta.total);
    });
};

storeModule.actions.exportExcel = function ({ commit }, payload) {
  return api.get('camp/recurrent-payments/export', {
    params: {
      id: payload.filter.id,
      filial_id: payload.filter.filial_id,
      autopayment_amount: payload.filter.autopayment_amount,
      autopayment_day: payload.filter.autopayment_day
    },
    responseType: 'arraybuffer'
  })
    .then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'recurrent-payments' + '.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
    });
};

storeModule.state.count = null;

storeModule.getters.getCount = state => state.count !== null ? state.count : 0;

storeModule.mutations.setCount = function (state, data) {
  state.count = data;
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
