import { CommonStore } from '../common-store';
import api from '@/services/gateway/api';

const storeModule = new CommonStore(
  'camp/tv/video_request',
  { name: 'camp.tv.video_request.index' }
);

storeModule.actions.getList = function ({ commit }, payload) {
  return api.get('camp/tv/video_request', { params: payload })
    .then((response) => {
      commit('setList', response.data);
      commit('totalRows', response.data.meta.total);
    });
};

storeModule.state.totalRows = null;

storeModule.getters.totalRows = state => state.totalRows !== null ? state.totalRows : 0;

storeModule.mutations.totalRows = function (state, data) {
  state.totalRows = data;
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
