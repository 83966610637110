import api from '@/services/gateway/api';
import router from '@/vue-instance/router';
import store from '@/vue-instance/store';

const state = {
  blocks: [],
  block: {}
};

const getters = {
  getBlocks: state => state.blocks,
  getLengthBlocks: state => state.blocks !== null ? state.blocks.length : 0,
  getBlock: state => state.block
};

const mutations = {
  setBlocks (state, data) {
    state.blocks = data;
  },
  setBlock (state, data) {
    state.block = data;
  }
};

const actions = {
  actionGetBlocks ({ commit }) {
    return api.get('camp/blocks')
      .then((response) => {
        commit('setBlocks', response.data.data);
      }
      );
  },
  actionGetBlock ({ commit }, payload) {
    return api.get('camp/blocks/' + payload + '/edit')
      .then((response) => {
        commit('setBlock', response.data.data);
      });
  },
  actionAddBlock ({ commit }, payload) {
    return api.post('camp/blocks', payload)
      .then((response) => {
        router.push({ name: 'camp.blocks.index' });
      }
      );
  },
  actionEditBlock ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/blocks/' + payload.id, payload);
  },
  actionRemoveBlock ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/blocks/' + payload.id, payload);
  },
  actionSyncBlockItems ({ dispatch }, payload) {
    return api.post(
      'camp/blocks/sync_items/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetBlocks');
        store.dispatch('camp/blockItems/actionGetBlockItems');
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
