import { CommonStore } from '../common-store';
import api from '@/services/gateway/api';

const storeModule = new CommonStore(
  'camp/tv/channel_category',
  { name: 'camp.tv.channel_category.index' }
);

storeModule.actions.getList = function ({ commit }, payload) {
  return api.get('camp/tv/channel_category', { params: payload })
    .then((response) => {
      commit('setList', response.data.data);
    });
};

storeModule.state.totalRows = null;

storeModule.getters.totalRows = state => state.totalRows !== null ? state.totalRows : 0;

storeModule.mutations.setTotalRows = function (state, data) {
  state.totalRows = data;
};

storeModule.state.listWithPaginate = [];

storeModule.getters.getListWithPaginate = state => state.listWithPaginate;

storeModule.mutations.setListWithPaginate = function (state, data) {
  state.listWithPaginate = data;
};

storeModule.actions.getListWithPaginate = function ({ commit }, payload) {
  return api.get('camp/tv/channel_category/paginate', { params: payload })
    .then((response) => {
      commit('setListWithPaginate', response.data);
      commit('setTotalRows', response.data.meta.total);
    });
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
