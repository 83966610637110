import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  pages: {},
  page: {}
};

const getters = {
  getPages: state => state.pages,
  getPage: state => state.page
};

const mutations = {
  setPages (state, data) {
    state.pages = data.data;
  },
  setPage (state, data) {
    state.page = data.data;
  }
};

const actions = {
  actionGetPages ({ commit }) {
    return api.get('camp/pages')
      .then((response) => {
        commit('setPages', response.data);
      }
      );
  },
  actionGetChildren ({ commit }, data) {
    return api.get('camp/pages/get_children', { params: data });
  },
  actionUp ({ commit }, payload) {
    return api.post('camp/pages/set_up', payload);
  },
  actionDown ({ commit }, payload) {
    return api.post('camp/pages/set_down', payload);
  },
  actionTop ({ commit }, payload) {
    return api.post('camp/pages/set_top', payload);
  },
  actionGetPage ({ commit }, payload) {
    return api.get('camp/pages/' + payload + '/edit')
      .then((response) => {
        commit('setPage', response.data);
      });
  },
  actionAddPage ({ commit }, payload) {
    return api.post('camp/pages', payload)
      .then((response) => {
        router.push({ name: 'camp.menu.index' });
      }
      );
  },
  actionEditPage ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/pages/' + payload.id, payload);
  },
  actionRemovePage ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/pages/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
