import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  categories: null,
  category: null
};

const getters = {
  getCategories: state => state.categories,
  getCategory: state => state.category,
  getLengthCategories: state => state.categories !== null ? state.categories.length : 0
};

const mutations = {
  setCategories (state, data) {
    state.categories = data.data;
  },
  setCategory (state, data) {
    state.category = data.data;
  }
};

const actions = {
  actionGetCategories ({ commit }) {
    return api.get('camp/page_catalog')
      .then((response) => {
        commit('setCategories', response.data);
      }
      );
  },
  actionGetCategory ({ commit }, payload) {
    return api.get('camp/page_catalog/' + payload + '/edit')
      .then((response) => {
        commit('setCategory', response.data);
      });
  },
  actionAddCategory ({ commit }, payload) {
    return api.post('camp/page_catalog', payload)
      .then((response) => {
        router.push({ name: 'camp.pageCatalog.index' });
      }
      );
  },
  actionEditCategory ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/page_catalog/' + payload.id, payload);
  },
  actionRemoveCategory ({ commit }, payload) {
    return api.delete('camp/page_catalog/' + payload.id);
  },
  actionCheckModel ({ commit }, payload) {
    return api.get('camp/page_catalog/check_model/' + payload.model);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
