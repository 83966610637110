import { CommonStore } from '../common-store';
import api from '@/services/gateway/api';
import moment from 'moment';

const storeModule = new CommonStore(
  'camp/boosters',
  { name: 'camp.boosters.index' }
);

storeModule.actions.getList = function ({ commit }, payload) {
  return api.get('camp/boosters', { params: payload })
    .then((response) => {
      commit('setList', response.data.data);
      commit('setCount', response.data.meta.total);
    });
};

storeModule.actions.exportExcel = function ({ commit }, payload) {
  return api.get('camp/boosters/export', { params: payload, responseType: 'arraybuffer' })
    .then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'booster_' +
        moment(payload.dateStart).format('DD.MM.YYYY') + '-' +
        moment(payload.dateEnd).format('DD.MM.YYYY') + '.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
    });
};

storeModule.state.count = null;

storeModule.getters.getCount = state => state.count !== null ? state.count : 0;

storeModule.mutations.setCount = function (state, data) {
  state.count = data;
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
