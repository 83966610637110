import api from '@/services/gateway/api';

const state = {
  list: []
};

const getters = {
  getList: (state) => state.list
};

const mutations = {
  setList (state, data) {
    state.list = data;
  }
};

const actions = {
  getList ({ commit }, payload) {
    return api.get('camp/tv/channels/sources', { params: payload })
      .then((response) => {
        commit('setList', response.data.data);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
