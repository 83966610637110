import router from '@/vue-instance/router';
import api from '@/services/gateway/api';

const state = {
  auctionPage: {}
};

const getters = {
  getAuctionPage: state => state.auctionPage
};

const mutations = {
  setAuctionPage (state, data) {
    state.auctionPage = data.data;
  }
};

const actions = {
  actionGetAuctionPage ({ commit }, payload) {
    return api.get('camp/auctions_page/' + payload + '/edit')
      .then((response) => {
        commit('setAuctionPage', response.data);
      });
  },
  actionAddAuctionPage ({ commit }, payload) {
    return api.post('camp/auctions_page', payload)
      .then((response) => {
        router.push({ name: 'camp.auction.index' });
      }
      );
  },
  actionEditAuctionPage ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/auctions_page/' + payload.id, payload);
  },
  actionRemoveAuctionPage ({ commit }, payload) {
    return api.delete('camp/auctions_page/' + payload.id);
  },
  actionPublish ({ commit }, payload) {
    return api.get('camp/auctions_page/publish/' + payload.model);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
