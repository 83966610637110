import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import localization from './modules/localization';
import validation from './modules/validation-errors';
import loader from './modules/loader';
import camp from './modules/camp';
import open from './modules/open';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'localization.language',
        'camp.auth.user',
        'camp.auth.menu',
        'camp.uiSettings.settings',
        'camp.uiSettings.backgroundMode'
      ]
    })
  ],
  modules: {
    localization,
    validation,
    loader,
    camp,
    open
  }
});
