import api from '@/services/gateway/api';

const state = {
  smsGroup: null
};

const getters = {
  getSmsGroup: state => state.smsGroup
};

const mutations = {
  setSmsGroup (state, data) {
    state.smsGroup = data;
  }
};

const actions = {
  actionGetSmsGroup ({ commit }) {
    api.get('camp/sms/distribution', { params: {
      size : 200,
      pageNum : 1
    } })
      .then((response) => {
        commit('setSmsGroup', response.data.data.items);
      })
      .catch(() => {
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

