import api from '@/services/gateway/api';

const state = {
  blocksTypes: null
};

const getters = {
  getBlocksTypes: state => state.blocksTypes
};

const mutations = {
  setBlocksTypes (state, data) {
    state.blocksTypes = data.data;
  }
};

const actions = {
  actionGetBlocksTypes ({ commit }) {
    return api.get('camp/block_types')
      .then((response) => {
        commit('setBlocksTypes', response.data);
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
