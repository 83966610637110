import api from '@/services/gateway/api';

const state = {
  purchasePage: null
};

const getters = {
  getPurchasePage: state => state.purchasePage
};

const mutations = {
  setPurchasePage (state, data) {
    state.purchasePage = data.data;
  }
};

const actions = {
  actionGetPurchasePage ({ commit }, payload) {
    return api.get('camp/purchases_page/' + payload + '/edit')
      .then((response) => {
        commit('setPurchasePage', response.data);
      });
  },
  actionAddPurchasePage ({ commit }, payload) {
    return api.post('camp/purchases_page', payload)
      .then((response) => {
        // router.push({name: 'camp.purchasePage.index'});
      }
      );
  },
  actionEditPurchasePage ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/purchases_page/' + payload.id, payload);
  },
  actionRemovePurchasePage ({ commit }, payload) {
    return api.delete('camp/purchases_page/' + payload.id);
  },
  actionPublish ({ commit }, payload) {
    return api.get('camp/purchases_page/publish/' + payload.model);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
