import { CommonStore } from '../common-store';
import router from '@/vue-instance/router';
import api from '@/services/gateway/api';

const storeModule = new CommonStore(
  'camp/knowledge/answer',
  { name: 'camp.knowledge.answer.index' }
);

let actions = {
  ...storeModule.actions,
  addItem ({ commit }, payload) {
    return api.post(storeModule.baseUrl, payload)
      .then((response) => {
        if (!payload) {
          router.push(storeModule.indexRoute);
        } else {
          router.push({ ...storeModule.indexRoute, query: { knowledge_question_id: payload.knowledge_question_id } });
        }
      });
  }
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: actions,
  mutations: storeModule.mutations
};
