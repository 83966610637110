import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  bonuses: null,
  bonus: null
};

const getters = {
  getBonuses: state => state.bonuses,
  getBonus: state => state.bonus,
};

const mutations = {
  setBonuses (state, data) {
    state.bonuses = data;
  },
  setBonus (state, data) {
    state.bonus = data;
  },
};

const actions = {
  actionGetBonuses ({ commit }) {
    return api.get('camp/business_metadata')
      .then((response) => {
        commit('setBonuses', response.data);
      });
  },
  actionGetBonus ({ commit }, payload) {
    return api.get('camp/business_metadata/' + payload + '/')
      .then((response) => {
        commit('setBonus', response.data);
      });
  },
  actionAddBonus ({ commit }, payload) {
    return api.post('camp/business_metadata', payload);
  },
  actionEditBonus ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/business_metadata/' + payload.id, payload);
  },
  actionRemoveBonus ({ commit }, payload) {
    return api.delete('camp/business_metadata/' + payload.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
