import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  sliders: []
};

const getters = {
  getSliders: state => state.sliders,
  getLengthSliders: state => state.sliders !== null ? state.sliders.length : 0
};

const mutations = {
  setSliders (state, data) {
    state.sliders = data;
  }
};

const actions = {
  actionGetSliders ({ commit }) {
    return api.get('camp/sliders')
      .then((response) => {
        commit('setSliders', response.data);
      }
      );
  },
  actionSyncSlides ({ dispatch }, payload) {
    return api.post(
      'camp/sliders/sync_slides/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetSliders');
      }
      );
  },
  actionAddSliders ({ commit }, payload) {
    return api.post('camp/sliders', payload)
      .then((response) => {
        router.push({ name: 'camp.sliders.index' });
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
