const state = {
  errors: {}
};

const getters = {
  getErrors: state => state.errors
};

const mutations = {
  'setErrors': (state, payload) => {
    state.errors = payload;
  },
  'unsetErrors': state => {
    state.errors = {};
  }
};

const actions = {
  setErrors ({ commit }, payload) {
    commit('setErrors', payload);
  },
  unsetErrors ({ commit }) {
    commit('unsetErrors');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
