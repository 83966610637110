import router from '@/vue-instance/router';
import api from '@/services/gateway/api';

const state = {
  auctions: null,
  auction: null
};

const getters = {
  getAuctions: state => state.auctions,
  getAuction: state => state.auction
};

const mutations = {
  setAuctions (state, data) {
    state.auctions = data.data;
  },
  setAuction (state, data) {
    state.auction = data.data;
  }
};

const actions = {
  actionGetAuctions ({ commit }) {
    return api.get('camp/auctions')
      .then((response) => {
        commit('setAuctions', response.data);
      });
  },
  actionGetChildren ({ commit }, data) {
    return api.get('camp/auctions/get_children', { params: data });
  },
  actionGetAuction ({ commit }, payload) {
    return api.get('camp/auctions/' + payload + '/edit')
      .then((response) => {
        commit('setAuction', response.data);
      });
  },
  actionAddAuction ({ commit }, payload) {
    return api.post('camp/auctions', payload)
      .then((response) => {
        router.push({ name: 'camp.auction.index' });
      }
      );
  },
  actionEditAuction ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/auctions/' + payload.id, payload);
  },
  actionRemoveAuction ({ commit }, payload) {
    return api.delete('camp/auctions/' + payload.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
