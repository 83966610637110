import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  subserviceTexts: [],
  subserviceText: {}
};

const getters = {
  getSubserviceTexts: state => state.subserviceTexts,
  getSubserviceText: state => state.subserviceText
};

const mutations = {
  setSubserviceTexts (state, data) {
    state.subserviceTexts = data;
  },
  setSubserviceText (state, data) {
    state.subserviceText = data.data;
  }
};

const actions = {
  actionGetSubserviceTexts ({ commit }) {
    return api.get('camp/subservice_texts')
      .then((response) => {
        commit('setSubserviceTexts', response.data.data);
      }
      );
  },
  actionGetSubserviceText ({ commit }, payload) {
    return api.get('camp/subservice_texts/' + payload + '/edit')
      .then((response) => {
        commit('setSubserviceText', response.data);
      });
  },
  actionAddSubserviceText ({ commit }, payload) {
    return api.post('camp/subservice_texts', payload)
      .then((response) => {
        router.push({ name: 'camp.subservice_texts.index' });
      }
      );
  },
  actionEditSubserviceText ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/subservice_texts/' + payload.id, payload);
  },
  actionRemoveSubserviceText ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/subservice_texts/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
