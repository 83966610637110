import api from '@/services/gateway/api';
import router from '@/vue-instance/router';
import store from '@/vue-instance/store';

const state = {
  advantages: [],
  advantage: {}
};

const getters = {
  getAdvantages: state => state.advantages,
  getLengthAdvantages: state => state.advantages !== null ? state.advantages.length : 0,
  getAdvantage: state => state.advantage
};

const mutations = {
  setAdvantages (state, data) {
    state.advantages = data;
  },
  setAdvantage (state, data) {
    state.advantage = data;
  }
};

const actions = {
  actionGetAdvantages ({ commit }) {
    return api.get('camp/advantages')
      .then((response) => {
        commit('setAdvantages', response.data.data);
      }
      );
  },
  actionGetAdvantage ({ commit }, payload) {
    return api.get('camp/advantages/' + payload + '/edit')
      .then((response) => {
        commit('setAdvantage', response.data.data);
      });
  },
  actionAddAdvantage ({ commit }, payload) {
    return api.post('camp/advantages', payload)
      .then((response) => {
        router.push({ name: 'camp.advantages.index' });
      }
      );
  },
  actionEditAdvantage ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/advantages/' + payload.id, payload);
  },
  actionRemoveAdvantage ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/advantages/' + payload.id, payload);
  },
  actionSyncAdvantageItems ({ dispatch }, payload) {
    return api.post(
      'camp/advantages/sync_items/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetAdvantages');
        store.dispatch('camp/advantageItems/actionGetAdvantageItems');
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
