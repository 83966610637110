import api from '@/services/gateway/api';

const state = {
  servicesTypes: null
};

const getters = {
  getServicesTypes: state => state.servicesTypes
};

const mutations = {
  setServicesTypes (state, data) {
    state.servicesTypes = data.data;
  }
};

const actions = {
  actionGetServicesTypes ({ commit }) {
    return api.get('camp/service_types')
      .then((response) => {
        commit('setServicesTypes', response.data);
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
