const state = {
  loader: false
};

const getters = {
  getLoader: state => state.loader
};

const mutations = {
  setLoader (state, payload) {
    state.loader = payload;
  }
};

const actions = {
  activateLoader ({ commit }) {
    commit('setLoader', true);
  },
  deactivateLoader ({ commit }) {
    commit('setLoader', false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
