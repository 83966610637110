import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  rateDetails: [],
  rateDetail: {}
};

const getters = {
  getRateDetails: state => state.rateDetails,
  getLengthRateDetails: state => state.rateDetails !== null ? state.rateDetails.length : 0,
  getRateDetail: state => state.rateDetail
};

const mutations = {
  setRateDetails (state, data) {
    state.rateDetails = data;
  },
  setRateDetail (state, data) {
    state.rateDetail = data;
  }
};

const actions = {
  actionGetRateDetails ({ commit }) {
    return api.get('camp/rate_details')
      .then((response) => {
        commit('setRateDetails', response.data.data);
      }
      );
  },
  actionGetRateDetail ({ commit }, payload) {
    return api.get('camp/rate_details/' + payload + '/edit')
      .then((response) => {
        commit('setRateDetail', response.data.data);
      });
  },
  actionAddRateDetail ({ commit }, payload) {
    return api.post('camp/rate_details', payload)
      .then((response) => {
        router.push({ name: 'camp.rate_details.index' });
      }
      );
  },
  actionEditRateDetail ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/rate_details/' + payload.id, payload);
  },
  actionRemoveRateDetail ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/rate_details/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
