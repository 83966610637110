import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  users: [],
  user: {
    token: null,
    id: null,
    email: null,
    username: null,
    user_roles: []
  }
};

const getters = {
  getUsers: (state) => state.users,
  getPagination: (state) => state.pagination,
  getUser: (state) => state.user,
  getLengthUsers: (state) => !state.users ? state.users.length : 0
};

const mutations = {
  setUsers (state, data) {
    state.users = data.data;
  },
  setPagination (state, data) {
    state.pagination = data.meta;
  },
  setUser (state, data) {
    state.user = data.data;
  }
};

const actions = {
  actionGetUsers ({ commit }, page) {
    return api.get('camp/users', { 'page': page })
      .then((response) => {
        commit('setUsers', response.data);
        commit('setPagination', response.data);
      }
      );
  },
  actionGetUserById ({ commit }, id) {
    return api.get('camp/users/' + id + '/edit').then((response) => {
      commit('setUser', response.data);
    }
    );
  },
  actionEditUser ({ commit }, userData) {
    return api.post('camp/users/' + userData.id, Object.assign(userData, { '_method': 'PATCH' }))
      .then((response) => {
        router.push({ name: 'camp.users' });
      }
      );
  },
  actionAddUser ({ commit }, userData) {
    return api.post('camp/users', userData)
      .then((response) => {
        router.push({ name: 'camp.users' });
      }
      );
  },
  actionRemoveUser ({ commit }, payload) {
    return api.delete('camp/users/' + payload.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
