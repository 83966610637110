import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import Notifications from 'vue-notification';

import VueClipboard from 'vue-clipboard2';

Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.config.productionTip = false;

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

export default Vue;
