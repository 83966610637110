import { CommonStore } from '../common-store';
import api from '@/services/gateway/api';

const storeModule = new CommonStore(
  'camp/isb/offers',
  { name: 'camp.offers.base.index' }
);

storeModule.actions.getList = function ({ commit }, payload) {
  return api.get('camp/isb/offers', { params: payload })
    .then((response) => {
      commit('setList', response.data);
    });
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
