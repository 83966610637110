import { appConfig } from '@/config/app';
import Vue from 'vue';
import Router from 'vue-router';
import TheEmptyRouterView from '@/components/TheEmptyRouterView.vue';
import routesCamp from './routes-camp';
import { languageMiddleware } from '@/vue-instance/router/middlewares/language-middleware';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/:language(' + appConfig.availableLanguages.join('|') + ')?',
      beforeEnter: (to, from, next) => {
        languageMiddleware(to, from, next);
        next();
      },
      component: TheEmptyRouterView,
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: 'main' */ '@/views/Temp.vue')
        },
        {
          path: 'camp',
          component: () => import(/* webpackChunkName: 'camp' */ '@/components/TheEmptyRouterView.vue'),
          children: routesCamp
        }
      ]
    }
  ]
});

export default router;
