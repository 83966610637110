// initial state
const state = {
  settings: {
    appLayout: {
      mhf: false,
      msf: false,
      msh: false,
      mbl: false,
      mfbg: false
    },
    globalMods: {
      mcpb: false,
      mhni: false,
      mda: false
    }
  },
  backgroundMode: 'mod-bg-1'
};

// getters
const getters = {
  getSettings: state => state.settings,
  getBackgroundMode: state => state.backgroundMode
};

// mutations
const mutations = {
  setMod (state, { group, modId, isEnabled }) {
    state.settings[group][modId] = isEnabled;
  },
  setBackgroundMode (state, value) {
    state.backgroundMode = value;
  },
  resetSettings (state) {
    for (let group in state.settings) {
      let value = state.settings[group];
      for (let key in value) {
        value[key] = false;
      }
    }
  }
};

// actions
const actions = {
  setMod ({ commit }, { group, modId, isEnabled }) {
    commit('setMod', { group, modId, isEnabled });
  },
  setBackgroundMode ({ commit }, payload) {
    commit('setBackgroundMode', payload);
  },
  resetSettings ({ commit }) {
    commit('resetSettings');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
