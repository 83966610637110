import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  list: [],
  item: {}
};

const getters = {
  getItems: state => state.list,
  getItem: state => state.item
};

const mutations = {
  setItems (state, data) {
    state.list = data;
  },
  setItem (state, data) {
    state.item = data;
  }
};

const actions = {
  actionGetList ({ commit }) {
    return api.get('camp/sapa/delivery-methods')
      .then((response) => {
        const { data } = response.data;

        if (data) {
          const payload = data.map(item => {
            const newCapacity = Object.values(item.capacities).reduce((total, value) => {
              return total + parseInt(value, 10);
            }, 0);

            return {
              ...item,
              capacity: newCapacity
            };
          });

          commit('setItems', payload);
        } else {
          commit('setItems', []);
        }
      }
      );
  },
  actionGetItem ({ commit }, id) {
    return api.get(`camp/sapa/delivery-methods/${id}/edit`)
      .then((response) => {
        const { data } = response.data;

        if (data) {
          const newCapacity = Object.values(data.capacities).reduce((total, value) => {
            return total + parseInt(value, 10);
          }, 0);

          const payload = {
            ...data,
            capacity: newCapacity
          };

          commit('setItem', payload);
        } else {
          commit('setItem', {});
        }
      }
      );
  },
  actionEditItem ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    const newCapacity = Object.values(payload.capacities).reduce((total, value) => {
      return total + parseInt(value, 10);
    }, 0);

    payload.is_active = payload.is_active === 'Да' ? 1 : 0;
    payload.capacity = newCapacity;

    return api.post(`camp/sapa/delivery-methods/${payload.id}`, payload).then((response) => {
      router.push({ name: 'camp.sapa.delivery_methods.index' });
    }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
