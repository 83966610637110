import { CommonStore } from '../common-store';
import api from '@/services/gateway/api';

const storeModule = new CommonStore(
  'camp/urls',
  { name: 'camp.short-links.index' }
);

storeModule.state.count = 0;
storeModule.state.perPage = 0;

storeModule.getters.getCount = state => state.count !== null ? state.count : 0;

storeModule.mutations.setCount = function (state, data) {
  state.count = data;
};

storeModule.getters.getPerPage = state => state.perPage;

storeModule.mutations.setPerPage = function (state, data) {
  state.perPage = data;
};

storeModule.actions.getList = function ({ commit }, payload) {
  return api.get('camp/urls', { params: payload })
    .then((response) => {
      commit('setList', response.data.data);
      commit('setCount', response.data.meta.total);
      commit('setPerPage', response.data.meta.per_page);
    });
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
