import api from '@/services/gateway/api';

const state = {
  mainServiceTypes: [],
  mainServiceTypesOptions: []
};

const getters = {
  getMainServiceTypes: state => state.mainServiceTypes,
  getMainServiceTypesOptions: state => state.mainServiceTypesOptions
};

const mutations = {
  'setMainServiceTypes': (state, payload) => {
    state.mainServiceTypes = payload;

    state.mainServiceTypesOptions = [];

    for (let index in payload) {
      if (payload.hasOwnProperty(index)) {
        let attr = payload[index];
        state.mainServiceTypesOptions.push(
          { value: parseInt(index), text: attr.name }
        );
      }
    }
  }
};

const actions = {
  setMainServiceTypes ({ commit }) {
    api.get('open/main_service_types')
      .then((response) => {
        commit('setMainServiceTypes', response.data);
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
