import { LanguageChecker } from '@/services/localization/language-checker';

const state = {
  language: 'ru'
};

const getters = {
  getLanguage: state => state.language
};

const mutations = {
  setLanguage (state, payload) {
    state.language = LanguageChecker.getCorrectLanguage(payload);
  }
};

const actions = {
  setLanguage ({ commit }, language: string) {
    commit('setLanguage', language);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
