import { CommonStore } from '../common-store';
import api from '@/services/gateway/api';

const storeModule = new CommonStore(
  'camp/pss-categories',
  { name: 'camp.pss-categories.index' }
);

storeModule.actions.getList = function ({ commit }, payload) {
  return api.get('camp/pss-categories', { params: payload })
    .then((response) => {
      commit('setList', response.data);
      commit('setCount', response.data.meta.total);
    });
};

storeModule.state.count = null;

storeModule.getters.getCount = state => state.count !== null ? state.count : 0;

storeModule.mutations.setCount = function (state, data) {
  state.count = data;
};

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
