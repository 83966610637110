import api from '@/services/gateway/api';
import router from '@/vue-instance/router';
import store from '@/vue-instance/store';

const state = {
  packages: [],
  package: {}
};

const getters = {
  getPackages: state => state.packages,
  getPackage: state => state.package
};

const mutations = {
  setPackages (state, data) {
    state.packages = data;
  },
  setPackage (state, data) {
    state.package = data.data;
    state.package.link = state.package.link === 'null' ? '' : state.package.link;
  }
};

const actions = {
  actionGetPackages ({ commit }) {
    return api.get('camp/packages')
      .then((response) => {
        commit('setPackages', response.data.data);
      }
      );
  },
  actionGetPackage ({ commit }, payload) {
    return api.get('camp/packages/' + payload + '/edit')
      .then((response) => {
        commit('setPackage', response.data);
      });
  },
  actionAddPackage ({ commit }, payload) {
    if (payload.hasOwnProperty('bigImageUrl')) delete payload['bigImageUrl'];
    if (payload.hasOwnProperty('smallImageUrl')) delete payload['smallImageUrl'];

    return api.post('camp/packages', payload)
      .then((response) => {
        router.push({ name: 'camp.packages.index' });
      }
      );
  },
  actionEditPackage ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    if (payload.hasOwnProperty('bigImageUrl')) delete payload['bigImageUrl'];
    if (payload.hasOwnProperty('smallImageUrl')) delete payload['smallImageUrl'];

    return api.post('camp/packages/' + payload.id, payload);
  },
  actionRemovePackage ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/packages/' + payload.id, payload);
  },
  actionSyncServiceType ({ dispatch }, payload) {
    return api.post(
      'camp/packages/sync_service_types/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetPackages');
        store.dispatch('camp/camp/serviceTypes/actionGetServicesTypes');
      }
      );
  },
  actionSyncMainServices ({ dispatch }, payload) {
    return api.post(
      'camp/packages/sync_main_services/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetPackages');
        store.dispatch('camp/camp/mainServices/actionGetMainServices');
      }
      );
  },
  actionSyncAdditionalServices ({ dispatch }, payload) {
    return api.post(
      'camp/packages/sync_additional_services/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetPackages');
        store.dispatch('camp/camp/additionalServices/actionGetAdditionalServices');
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
