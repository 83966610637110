import api from '@/services/gateway/api';

const state = {
  smsTemplate: [],
  smsDepartment: [],
  errorMsg: null,
  totalCount: 0
};

const getters = {
  getTemplate: (state) => state.smsTemplate,
  getSmsDepartment: (state) => state.smsDepartment,
  getError: (state) => state.errorMsg,
  getCount: (state) => state.totalCount
};

const mutations = {
  setSmsTemplate (state, data) {
    state.smsTemplate = data;
  },
  setSmsDepartment (state, data) {
    state.smsDepartment = data;
  },
  setErrorMsg (state, data) {
    state.errorMsg = data;
  },
  setCount (state, data) {
    state.totalCount = data;
  }
};

const actions = {
  async actionGetSmsList ({ commit }, payload) {
    try {
      const { data } = await api.get('camp/sms/logs', { params: payload });

      return {
        items: data.data.items,
        total: data.data.total
      };
    } catch (error) {
      commit('setErrorMsg', error);

      return {
        items: [],
        total: 0
      };
    }
  },

  actionGetTemplate ({ commit }, payload) {
    api
      .get('camp/sms/templates', { params: payload })
      .then((response) => {
        commit('setSmsTemplate', response.data.items);
      })
      .catch(() => {});
  },

  actionGetDepartments ({ commit }, payload) {
    api.get('camp/sms/departments', { params: payload }).then((response) => {
      commit('setSmsDepartment', response.data.items);
    })
      .catch(() => {});
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
