import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  blockItems: [],
  blockItem: {}
};

const getters = {
  getBlockItems: state => state.blockItems,
  getLengthBlockItems: state => state.blockItems !== null ? state.blockItems.length : 0,
  getBlockItem: state => state.blockItem
};

const mutations = {
  setBlockItems (state, data) {
    state.blockItems = data;
  },
  setBlockItem (state, data) {
    state.blockItem = data;
  }
};

const actions = {
  actionGetBlockItems ({ commit }) {
    return api.get('camp/block_items')
      .then((response) => {
        commit('setBlockItems', response.data.data);
      }
      );
  },
  actionGetBlockItem ({ commit }, payload) {
    return api.get('camp/block_items/' + payload + '/edit')
      .then((response) => {
        commit('setBlockItem', response.data.data);
      });
  },
  actionAddBlockItem ({ commit }, payload) {
    if (payload.hasOwnProperty('imageUrl')) delete payload['imageUrl'];
    return api.post('camp/block_items', payload)
      .then((response) => {
        router.push({ name: 'camp.block_items.index' });
      }
      );
  },
  actionEditBlockItem ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    if (payload.hasOwnProperty('imageUrl')) delete payload['imageUrl'];
    return api.post('camp/block_items/' + payload.id, payload);
  },
  actionRemoveBlockItem ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/block_items/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
