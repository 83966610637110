import { CommonStore } from '../common-store';

const storeModule = new CommonStore(
  'camp/offers',
  { name: 'camp.offers.base.index' }
);

export default {
  namespaced: storeModule.namespaced,
  state: storeModule.state,
  getters: storeModule.getters,
  actions: storeModule.actions,
  mutations: storeModule.mutations
};
