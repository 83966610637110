import api from '@/services/gateway/api';
import router from '@/vue-instance/router';
import store from '@/vue-instance/store';

const state = {
  subservice_selects: [],
  subserviceSelect: {}
};

const getters = {
  getSubserviceSelects: state => state.subservice_selects,
  getSubserviceSelect: state => state.subserviceSelect
};

const mutations = {
  setSubserviceSelects (state, data) {
    state.subservice_selects = data;
  },
  setSubserviceSelect (state, data) {
    state.subserviceSelect = data.data;
  }
};

const actions = {
  actionGetSubserviceSelects ({ commit }) {
    return api.get('camp/subservice_selects')
      .then((response) => {
        commit('setSubserviceSelects', response.data.data);
      }
      );
  },
  actionGetSubserviceSelect ({ commit }, payload) {
    return api.get('camp/subservice_selects/' + payload + '/edit')
      .then((response) => {
        commit('setSubserviceSelect', response.data);
      });
  },
  actionAddSubserviceSelect ({ commit }, payload) {
    return api.post('camp/subservice_selects', payload)
      .then((response) => {
        router.push({ name: 'camp.subservice_selects.index' });
      }
      );
  },
  actionEditSubserviceSelect ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/subservice_selects/' + payload.id, payload);
  },
  actionRemoveSubserviceSelect ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/subservice_selects/' + payload.id, payload);
  },
  actionSyncSubserviceOption ({ dispatch }, payload) {
    return api.post(
      'camp/subservice_selects/sync_options/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetSubserviceSelects');
        store.dispatch('camp/subserviceOptions/actionGetSubserviceOptions');
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
