import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AxiosStrategyFactory } from '@/services/gateway/axios-strategy-factory';
import { appConfig } from '@/config/app';
import { AxiosStrategyInterface } from '@/services/gateway/strategy/contracts/axios-strategy-interface';

const api = axios.create({});

const defineRequestInterceptor = (requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  requestConfig.baseURL = appConfig.apiBaseUrl; // @TODO baseUrl should be translatable ( for example /en/api/v1.0/something )
  const requestUrl = requestConfig.url || '';

  return new Promise((resolve) => {
    AxiosStrategyFactory
      .make(requestUrl)
      .then((strategy: AxiosStrategyInterface) => {
        requestConfig = strategy.getRequestConfig(requestConfig);
        resolve(requestConfig);
      });
  });
};

const defineResponseInterceptor = (responseConfig: AxiosResponse): Promise<AxiosResponse> => {
  let requestUrl = (responseConfig.config.url) || ''; // @TODO do it in an another service
  requestUrl = requestUrl.replace(responseConfig.config.baseURL || '', '');

  return new Promise((resolve) => {
    AxiosStrategyFactory
      .make(requestUrl)
      .then((strategy: AxiosStrategyInterface) => {
        responseConfig = strategy.getResponseConfig(responseConfig);
        resolve(responseConfig);
      });
  });
};

const defineResponseErrorHandler = (config: AxiosError): Promise<AxiosError> => {
  return new Promise((resolve, reject) => {
    const responseConfig = config.response;
    if (responseConfig) {
      let requestUrl = (responseConfig.config.url) || ''; // @TODO do it in an another service
      requestUrl = requestUrl.replace(responseConfig.config.baseURL || '', '');

      AxiosStrategyFactory
        .make(requestUrl)
        .then((strategy: AxiosStrategyInterface) => {
          reject(strategy.getResponseErrorConfig(config));
        });
    } else {
      reject(config);
    }
  });
};

api
  .interceptors
  .request
  .use(defineRequestInterceptor);

api
  .interceptors
  .response
  .use(defineResponseInterceptor, defineResponseErrorHandler); // @TODO define error response config

export {
  api
};
export default api;
