import api from '@/services/gateway/api';

const state = {
  sections: []
};

const getters = {
  getSections: (state) => state.sections
};

const mutations = {
  setSections (state, data) {
    state.sections = data.data;
  }
};

const actions = {
  actionGetSections ({ commit }, payload) {
    return api.get('camp/sections').then((response) => {
      commit('setSections', response.data);
    }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
