<template>
  <div id="app">
    <router-view />
    <notifications
      position="top center"
      :duration="4000"
      :width="320"
      classes="vue-notification kt-notify"
    />
  </div>
</template>

<script>
console.log(process.env.VUE_APP_API_BASE_URL)
export default {
  name: 'App'
};
</script>
