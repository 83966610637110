import api from '@/services/gateway/api';

// initial state
const state = {
  regions: [],
  regionOptions: []
};

// getters
const getters = {
  getRegions: state => state.regions,
  getRegionOptions: state => state.regionOptions
};

// mutations
const mutations = {
  setRegions (state, data) {
    state.regions = data;

    state.regionOptions = data.map(function (region, key) {
      return { value: region.id, text: region.name };
    });
  }
};

// actions
const actions = {
  setRegions ({ commit }, payload) {
    api.get('open/regions').then((response) => {
      commit('setRegions', response.data.data);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
