import api from '@/services/gateway/api';
import router from '@/vue-instance/router';
import store from '@/vue-instance/store';

const state = {
  services: null,
  service: null
};

const getters = {
  getServices: state => state.services,
  getLengthServices: state => state.services !== null ? state.services.length : 0,
  getService: state => state.service
};

const mutations = {
  setServices (state, data) {
    state.services = data;
  },
  setService (state, data) {
    state.service = data.data;
  }
};

const actions = {
  actionGetServices ({ commit }) {
    return api.get('camp/services')
      .then((response) => {
        commit('setServices', response.data);
      }
      );
  },
  actionGetService ({ commit }, payload) {
    return api.get('camp/services/' + payload + '/edit')
      .then((response) => {
        commit('setService', response.data);
      });
  },
  actionAddService ({ commit }, payload) {
    if (payload.hasOwnProperty('bigImageUrl')) delete payload['bigImageUrl'];
    if (payload.hasOwnProperty('smallImageUrl')) delete payload['smallImageUrl'];

    return api.post('camp/services', payload)
      .then((response) => {
        router.push({ name: 'camp.services.index' });
      }
      );
  },
  actionEditService ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    if (payload.hasOwnProperty('bigImageUrl')) delete payload['bigImageUrl'];
    if (payload.hasOwnProperty('smallImageUrl')) delete payload['smallImageUrl'];

    return api.post('camp/services/' + payload.id, payload);
  },
  actionRemoveService ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/services/' + payload.id, payload);
  },
  actionSyncServiceType ({ dispatch }, payload) {
    return api.post(
      'camp/services/sync_service_types/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetServices');
        store.dispatch('camp/serviceTypes/actionGetServicesTypes');
      }
      );
  },
  actionSyncPackage ({ dispatch }, payload) {
    return api.post(
      'camp/services/sync_packages/' + payload.id,
      {
        items: payload.items
      },
      {},
      false)
      .then((response) => {
        dispatch('actionGetServices');
        store.dispatch('camp/packages/actionGetPackages');
      }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
