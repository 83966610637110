import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  slides: [],
  slide: {}
};

const getters = {
  getSlides: state => state.slides,
  getLengthSlides: state => state.slides !== null ? state.slides.length : 0,
  getSlide: state => state.slide
};

const mutations = {
  setSlides (state, data) {
    state.slides = data.data;
  },
  setSlide (state, data) {
    state.slide = data.data;
  }
};

const actions = {
  actionGetSlides ({ commit }) {
    return api.get('camp/slides')
      .then((response) => {
        commit('setSlides', response.data);
      }
      );
  },
  actionGetSlide ({ commit }, payload) {
    return api.get('camp/slides/' + payload + '/edit')
      .then((response) => {
        commit('setSlide', response.data);
      });
  },
  actionAddSlide ({ commit }, payload) {
    if (payload.hasOwnProperty('imageUrl')) delete payload['imageUrl'];
    if (payload.hasOwnProperty('desktopUrl')) delete payload['desktopUrl'];
    if (payload.hasOwnProperty('mobileUrl')) delete payload['mobileUrl'];

    return api.post('camp/slides', payload)
      .then((response) => {
        router.push({ name: 'camp.slides.index' });
      }
      );
  },
  actionEditSlide ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    if (payload.hasOwnProperty('imageUrl')) delete payload['imageUrl'];
    if (payload.hasOwnProperty('desktopUrl')) delete payload['desktopUrl'];
    if (payload.hasOwnProperty('mobileUrl')) delete payload['mobileUrl'];

    return api.post('camp/slides/' + payload.id, payload);
  },
  actionRemoveSlide ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/slides/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
