import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  advantageItems: [],
  advantageItem: {}
};

const getters = {
  getAdvantageItems: state => state.advantageItems,
  getLengthAdvantageItems: state => state.advantageItems !== null ? state.advantageItems.length : 0,
  getAdvantageItem: state => state.advantageItem
};

const mutations = {
  setAdvantageItems (state, data) {
    state.advantageItems = data;
  },
  setAdvantageItem (state, data) {
    state.advantageItem = data;
  }
};

const actions = {
  actionGetAdvantageItems ({ commit }) {
    return api.get('camp/advantage_items')
      .then((response) => {
        commit('setAdvantageItems', response.data.data);
      }
      );
  },
  actionGetAdvantageItem ({ commit }, payload) {
    return api.get('camp/advantage_items/' + payload + '/edit')
      .then((response) => {
        commit('setAdvantageItem', response.data.data);
      });
  },
  actionAddAdvantageItem ({ commit }, payload) {
    return api.post('camp/advantage_items', payload)
      .then((response) => {
        router.push({ name: 'camp.advantage_items.index' });
      }
      );
  },
  actionEditAdvantageItem ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/advantage_items/' + payload.id, payload);
  },
  actionRemoveAdvantageItem ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/advantage_items/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
