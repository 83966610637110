import api from '@/services/gateway/api';
import router from '@/vue-instance/router';

const state = {
  communicationChannels: [],
  communicationChannel: {}
};

const getters = {
  getCommunicationChannels: state => state.communicationChannels,
  getLengthCommunicationChannels: state => state.communicationChannels !== null ? state.communicationChannels.length : 0,
  getCommunicationChannel: state => state.communicationChannel
};

const mutations = {
  setCommunicationChannels (state, data) {
    state.communicationChannels = data;
  },
  setCommunicationChannel (state, data) {
    state.communicationChannel = data;
  }
};

const actions = {
  actionCommunicationChannels ({ commit }) {
    return api.get('camp/communication_channels')
      .then((response) => {
        commit('setCommunicationChannels', response.data.data);
      }
      );
  },
  actionGetCommunicationChannel ({ commit }, payload) {
    return api.get('camp/communication_channels/' + payload + '/edit')
      .then((response) => {
        commit('setCommunicationChannel', response.data.data);
      });
  },
  actionAddCommunicationChannel ({ commit }, payload) {
    return api.post('camp/communication_channels', payload)
      .then((response) => {
        router.push({ name: 'camp.communication_channels.index' });
      }
      );
  },
  actionEditCommunicationChannel ({ commit }, payload) {
    payload['_method'] = 'PATCH';
    return api.post('camp/communication_channels/' + payload.id, payload);
  },
  actionRemoveCommunicationChannel ({ commit }, payload) {
    payload['_method'] = 'DELETE';
    return api.post('camp/communication_channels/' + payload.id, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
